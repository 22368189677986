import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const StatutsPage = () => (
  <Layout>
    <SEO title="L'association Agro Bio Conso" />
    <section className="hero pattern-zigzag-light" style={{marginTop: -4}}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title dreamland is-size-2">
              Les statuts de l'association Agro Bio Conso
            </h1>
          </div>
        </div>
      </section>
      <section>
        <div className="container" style={{padding: "0 1rem"}}>
          <article className="statuts has-text-justified">
            <h3>Article 1er</h3>
            <p>Il est fondé entre les adhérents aux présents statuts une association régie par la loi du 1er juillet 1901 et le décret du 16 août 1901, ayant pour titre : Agro Bio Conso</p>
            <p>La durée de l'association est illimitée.</p>
            <h3>Article 2 - Objet</h3>
            <p>Agro Bio Conso est une association de consommateurs de produits issus de l'agriculture biologique. Elle a pour objet d'organiser et de coordonner en Franche Comté un réseau de groupements d'achats de produits biologiques pour:</p>
            <ul>
              <li>démocratiser la consommation des produits agrobiologiques par la diminution des prix et la facilité d'accès, notamment par le développement constant de son implantation territoriale,</li>
              <li>sensibiliser les consommateurs sur les relations entre alimentation, santé et environnement,</li>
              <li>Défendre les intérêts des consommateurs de produits agrobiologiques</li>
              <li>favoriser le développement de l’ agrobiologie en établissant des relations économiques et sociales de proximité avec un réseau de producteurs agrobiologiques et en aidant à l’établissement de nouveaux producteurs .</li>
            </ul>
            <h3>Article 3 - Siège social</h3>
            <p>Le siège social est : 77 grande rue, 25360, Nancray.</p>
            <p>Ce lieu peut être changé par le conseil de gestion.</p>
            <h3>Article 4 - Composition</h3>
            <p>L’association nationale est composée des groupes de consommateurs organisés en partenariat avec les agriculteurs biologiques. Au niveau local chaque groupe est composé :</p>
            <ul>
              <li>membres actifs</li>
              <li>adhérents</li>
            </ul>
            <h3>Article 5 - Admission</h3>
            <p>Pour faire partie de l’association :</p>
            <ul>
              <li>au niveau national chaque groupe doit s’engager à respecter les statuts et payer la cotisation annuelle à la structure nationale.</li>
              <li>au niveau local chaque adhérent doit signer la charte et payer la cotisation annuelle à son groupe.</li>
            </ul>
            <h3>Article 6 - Radiation et exclusion</h3>
            <p>La qualité de membre se perd par :</p>
            <ul>
              <li>le non paiement de la cotisation</li>
              <li>la démission</li>
              <li>le décès,</li>
            </ul>
            <p>l’exclusion prononcée par le Conseil de gestion en ce qui concerne les groupes régionaux et locaux, et le conseil d’administration pour les adhérents (personnes physiques ) pour tout fait qui pourrait porter préjudice à l’association</p>
            <h3>Article 7 - Ressources</h3>
            <p>A chaque niveau (national, régional, local), les ressources de l’association comprennent :</p>
            <ul>
              <li>les cotisations dont le montant est fixé chaque année par l’Assemblée générale,</li>
              <li>les dons sous réserve d’acceptation par le Conseil de gestion ou le Conseil d’administration,</li>
              <li>toutes autres ressources autorisées par les textes législatifs et réglementaires en vigueur et en conformité avec les présents statuts.</li>
              <li>L’association ne peut percevoir de subventions publiques ni de financement provenant d’organisations à caractère commercial, politique ou religieux, à l’exception de la mise à disposition gratuite de locaux.</li>
            </ul>
            <p>La cotisation couvre une période de 12 mois consécutifs à compter du 1er jour du mois d’encaissement. Elle sera renouvelée à la date anniversaire de l’adhésion.</p>
            <h3>Article 8 - Conseil d'Administration</h3>
            <h4>8.1 La structure nationale :</h4>
            <p>Le Conseil de Gestion a pour vocation :</p>
            <ul>
              <li>la gestion du système informatique centrale.</li>
              <li>Développer le réseau sur tout le territoire national</li>
              <li>Présenter la synthèse de l'activité de l'ensemble des groupes locaux,</li>
              <li>Représenter l'association lorsque cette représentation ne relève pas des groupes locaux.</li>
            </ul>
            <p>Il est composé au plus de 5 membres, dont un président, un vice président, un secrétaire et un trésorier, cooptés initialement par les membres fondateurs puis par les membres du Conseil de gestion en fonction.</p>
            <p>Le conseil de gestion agit sous le contrôle des représentants des groupes locaux auxquels il présente un rapport moral et financier.</p>
            <p>L’Assemblée Générale nationale (AGN) est constituée des représentants élus lors des AGR, le nombre de ces représentants étant défini dans le règlement intérieur. L’AGN se prononce sur l’organisation et la coordination des groupes régionaux et la gestion du CG.</p>
            <p>Le Conseil de surveillance (CS), est tenu informé des décisions prises par le CG et s’assure que celles-ci sont conformes aux statuts, à la charte et aux décisions de l’AG. Il fait son rapport à l’AG et peut demander la convocation d’une AG extraordinaire.</p>
            <h4>8.2 La structure régionale</h4>
            <p>L’Assemblée Générale régionale (AGR) est composée des consommateurs et des producteurs de la Région telle qu’elle sera définie par le Conseil de Gestion. L’AGR se prononce sur l’organisation et la coordination des groupes locaux de la région. Elle élit ses représentants à l’AGN et au CS, en veillant à ce qu’il y ait parité entre les consommateurs et les producteurs.</p>
            <p>L’échelon régional se dote d’un Bureau Régional (président, secrétaire et trésorier) et si besoin d’un Conseil d’administration régional (CAR) élus lors de l’AGR.</p>
            <p>L’échelon régional peut avoir une activité propre (animations, conférences, visites, …) nécessitant un budget dont les modalités seront arrêtées par l’AGR.</p>
            <h4>8.3 La structure locale </h4>
            <ul>
              <li>est dirigée par un Conseil d’administration local (CAL) élu par l’Assemblée générale locale (AGL), de manière à ce qu’il y ait au moins un représentant par point de dépôt. Dans un souci de souplesse, le choix du nombre des administrateurs ainsi que les modalités de son renouvellement est laissé à la discrétion de chaque AGL. Les modalités de désignation des membres du CAL pourront être précisées dans le règlement intérieur du groupe.</li>
              <li>Chaque CAL désigne en son sein un Bureau Local composé d’au moins un président, un secrétaire et un trésorier.</li>
              <li>Entre 2 AGL, le CAL peut coopter de nouveaux membres pour suppléer à une démission ou une défection, ce, en veillant au respect du principe de représentation de chaque point de dépôt, tel que défini plus haut. Le ou les nouveaux membres auront par la suite à être régulièrement élus par l’AGL.</li>
              <li>L’AGL décide de l’implantation des points de dépôt, de la participation à des manifestations, de l’organisation d’animations, conférences, visites, …, et définit la gamme des produits en concertation avec le producteur-distributeur associé.</li>
            </ul>
            <p>Le Président représente l’association dans tous les actes de la vie civile, dans la limite de ses attributions territoriales (national, régional ou local). Il ordonnance les dépenses. Il représente l’association en justice. Pour ce faire, il reçoit mandat du Conseil de gestion ou d’administration. En cas de représentation en justice, le Président ne peut être remplacé que par un mandataire disposant d’une procuration spéciale du CG ou du CA.</p>
            <p>Dans le cas où une question relève conjointement de diverses instances nationale , régionale, locale, une concertation devra avoir lieu entre ces niveaux. En cas de désaccord entre différents niveaux territoriaux, le Conseil de gestion fera office d’arbitre. L’AGN suivante devra se prononcer définitivement sur le litige. La décision de l’AGN sera souveraine.</p>
            <p>Les représentants de l’association doivent jouir du plein exercice de leurs droits civiques. Toutes les fonctions au sein de l’association sont bénévoles. L’association n’emploie pas de salariés.</p>
            <h3>Article 9 - Réunion du Conseil de gestion ou d’administration</h3>
            <p>Le Conseil de gestion se réunit quand les circonstances le nécessitent ou sur demande du tiers de ses membres. Le Conseil de surveillance est informé des réunions du CG, de son ordre du jour et de ses délibérations. Toutes propositions et remarques du CS feront l’objet d’une délibération du Conseil de gestion qui devra motiver ses décisions qui pourront être remises en cause à l’AGN suivante.</p>
            <p>Pour les groupes régionaux et locaux, le Conseil d’administration se réunit, quand les circonstances le nécessitent ou sur demande du tiers de ses membres, sur convocation du Président. Les décisions sont prises à la majorité des voix des présents. En cas de partage, la voix du Président est prépondérante.</p>
            <p>Tout membre du Conseil qui sans excuse, aura manqué 3 réunions consécutives, pourra être considéré comme démissionnaire.</p>
            <p>Les réunions peuvent être physiques ou organisées à distance (téléconférence, chat internet, forum internet, courrier, …).</p>
            <h3>Article 10 - Assemblée générale ordinaire</h3>
            <p>L’Assemblée générale locale et régionale comprend tous les membres de l’association dans le cadre territorial concerné.</p>
            <p>Pour la structure nationale, les membres sont les représentants élus des groupes régionaux. (voir article 8).</p>
            <p>L’Assemblée générale (l’AGN , AGR et AGL) se réunit au moins tous les 2 ans. L’année sans assemblée générale, un rapport d’exercice portant sur l’année écoulée sera envoyé aux adhérents pour leur information.</p>
            <p>Trois semaines au moins avant la date fixée, les membres de l’association sont convoqués par leur Président. Cette convocation peut se faire par tout moyen de communication. L’ordre du jour est communiqué en même temps que la convocation.</p>
            <p>Le Président préside l’assemblée et expose le rapport moral de l’association.</p>
            <p>Le trésorier rend compte de sa gestion et soumet les comptes à l’approbation de l’assemblée.</p>
            <p>L’ AGN et l’AGR procèdent au renouvellement du CA. L’AGR désigne ses représentants à l’AGN et au CS (v. article 8).</p>
            <p>Les questions inscrites à l’ordre du jour sont prioritaires.</p>
            <h3>Article 11 - Assemblée générale extraordinaire</h3>
            <p>Sur la demande du conseil d’administration ou sur la demande de la moitié + 1 des membres inscrits, le Président peut convoquer une assemblée générale extraordinaire suivant les formalités prévues à l’article 10.</p>
            <h3>Article 12 - Conventions</h3>
            <p>Chaque association locale et régionale pourra établir un règlement intérieur précisant ses modalités de fonctionnement. Il sera adopté par l’ AG et pourra être modifié par elle. Une convention contractualisera les relations entre les consommateurs et les producteurs. Elle sera à la base de la charte que chaque nouveau membre signera au moment de son adhésion.</p>
            <h3>Article 13 - Modifications statutaires</h3>
            <p>Les modifications statutaires devront être adoptées par 75% des membres présents ou représentés. Elles ne pourront avoir lieu que lors d’une Assemblée générale extraordinaire et en aucun cas, remettre en cause l’esprit de l’objet statutaire.</p>
            <h3>Article 14 - Dissolution</h3>
            <p>Elle ne peut être prononcée que lors d’une assemblée générale extraordinaire par la volonté des membres à l’unanimité ou lorsque l’objet social n’a plus lieu d’être.</p>
            <p>Si la dissolution est prononcée, plusieurs liquidateurs sont nommés pour réaliser l’actif s’il y a lieu et le dévolu conformément à l’article 9 de la loi du 1/7/1901 et au décret du 16/1/1901.</p>
          </article>
        </div>
      </section>
  </Layout>
)

export default StatutsPage
